import React, { useState, useEffect, useRef } from 'react';
import './Login.scss';
import logo from '../../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import { emailReg, passwordReg } from '../../regex/regex';
import { useForm } from 'react-hook-form';
import { getAccessToken, setUser } from '../../services/AuthService';
import { LoginData } from '../../models/LoginData';
import { Toast } from 'primereact/toast';

const Login = () => {
  const [rememberMe, setRememberMe] = useState<boolean>(
    localStorage.getItem('rememberMe') ? true : false
  );
  const [rememberUser, setRememberUser] = useState<any>({
    email: '',
    password: ''
  });
  const login = localStorage.getItem('amss-admin-user') ? true : false;
  const navigate = useNavigate();
  const toast = useRef<any>(null);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    values: {
      email: rememberUser.email ?? '',
      password: rememberUser.password ?? ''
    },
    mode: 'onSubmit',
    shouldFocusError: true
  });

  useEffect(() => {
    try {
      setRememberUser(JSON.parse(localStorage.getItem('rememberMe') || ''));
    } catch (error) {
      null;
    }
  }, []);

  useEffect(() => {
    if (login) {
      navigate('/dashboard', { replace: true });
    }
  }, [login]);

  const handleRegistrationData = (data: LoginData) => {
    getAccessToken(data.email, data.password)
      .then((response) => {
        setUser(response.data.result);
        navigate('/dashboard', { replace: true });
      })
      .catch((err) =>
        toast.current.show({
          severity: 'error',
          detail: `Neispravni korisnicki podaci`,
          life: 3000
        })
      );
    if (rememberMe) {
      localStorage.setItem(
        'rememberMe',
        JSON.stringify({ email: data.email, password: data.password })
      );
    } else {
      localStorage.removeItem('rememberMe');
    }
  };

  const isValidEmail = (email: string) => emailReg.test(email);

  const handleEmailValidation = (email: string) => {
    const isValid = isValidEmail(email);

    return isValid;
  };

  const isValidPassword = (password: string) => passwordReg.test(password);

  const handlePasswordValidation = (password: string) => {
    const isValid = isValidPassword(password);

    return isValid;
  };

  const handleRimemberMe = () => {
    return setRememberMe(!rememberMe);
  };

  return (
    <div className="login-page">
      <Toast ref={toast} position={'top-right'} />
      <form onSubmit={handleSubmit(handleRegistrationData)} className="login-form">
        <div className="inner-box">
          <div className="first-row">
            <div className="wrapper">
              <label className="label">Email</label>
              <input
                className={errors.email ? 'input-error' : 'input'}
                {...register('email', {
                  required: true,
                  validate: handleEmailValidation
                })}
                placeholder={'First Name'}
              />
              {errors.email && <p className="message">Email format nije ispravan.</p>}
            </div>
            <div className="wrapper">
              <label className="label">Password</label>
              <input
                className={errors.password ? 'input-error' : 'input'}
                {...register('password', {
                  required: true
                  // validate: handlePasswordValidation
                })}
                type="password"
                placeholder={'Password'}
              />
              {/* {errors.password && (
                <p className="message">
                  1 uppercase letter, 1 lowercase letter, 1 number and a minimum length of 8
                  characters
                </p>
              )} */}
            </div>
          </div>
          <div className="second-row">
            <div className="remember-me">
              <input
                checked={rememberMe}
                onChange={handleRimemberMe}
                className="checkbox"
                type="checkbox"
              />
              <label>Remember Me</label>
            </div>
            <div className="forgot-password">
              <p>Forgot Password?</p>
            </div>
          </div>
          <hr className="line"></hr>
          <input style={{ display: 'none' }} id="register-btn" type="submit" />
          <label className="button" htmlFor="register-btn">
            LOG IN
          </label>
        </div>
      </form>
      <div className="amss-image">
        <img src={logo} alt="logo" className="image" />
      </div>
    </div>
  );
};

export default Login;
