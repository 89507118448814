import { activeBanner } from '../../services/BannerService';
import './ModalActiveBanner.scss';

const ModalActiveBanner = (props: any) => {
  const handleActiveBanner = () => {
    return activeBanner(props.activeId)
      .then((response) => {
        props.handleShowModalActiveBanner();
        props.handleShowActiveBannerToast();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="modal-active-banner">
      <div className="header-container">
        <p className="header">{'Aktivan baner'}</p>
        <button onClick={props.handleShowModalActiveBanner} className="delete-btn">
          <i className="pi pi-times" style={{ color: 'white' }}></i>
        </button>
      </div>
      <p className="text">{`Da li ste sigurni da želite da postavite novi aktivan baner?`}</p>
      <div className="btn-container">
        <button onClick={handleActiveBanner} className="btn yellow">
          <p className="blue-text">Da</p>
        </button>
        <button onClick={props.handleShowModal} className="btn blue">
          Ne
        </button>
      </div>
    </div>
  );
};

export default ModalActiveBanner;
