import axios, { AxiosRequestConfig } from 'axios';
import { Config } from '../config/config';
import { activeUser } from './AuthService';
// import { useNavigate } from 'react-router-dom';

export const axiosDefaults: AxiosRequestConfig = {
  baseURL: Config.apiRoot,
  withCredentials: true
};

export const axiosInstance = axios.create(axiosDefaults);
axiosInstance.interceptors.request.use((request) => {
  const token = localStorage.getItem('token');
  if (activeUser && token) {
    // @ts-ignore
    request.headers.Authorization = 'Bearer ' + token;
  }
  return request;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // whatever you want to do with the error
    if (error.response.status == 401) {
      // window.location.href = '/login';
    }
    throw error;
  }
);
