import React, { useState, useRef, useEffect } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { PageLayout } from '../../layout/PageLayout/PageLayout';
import './NewBanner.scss';
import phone from '../../assets/phone.png';
import InputField from '../../components/InputField/InputField';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { useParams } from 'react-router-dom';
import { createBanner, getBanner, updateBanner } from '../../services/BannerService';
import { useNavigate } from 'react-router-dom';
import { deleteFile, uploadFile } from '../../services/FileService';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';

const NewBanner = () => {
  const [totalSize, setTotalSize] = useState(0);
  const [image, setImage] = useState<any>(undefined);
  const [uploadImage, setUploadImage] = useState<any>();
  let serverUrl = process.env.REACT_APP_API_URL;
  let storageUrl = serverUrl + 'files/storage/';
  const toast = useRef<any>(null);
  const fileUploadRef = useRef<any>(null);
  const { RangePicker } = DatePicker;
  const [rangeDate, setRangeDate] = useState<any>([]);
  const [data, setData] = useState({
    name: '',
    expiry: '',
    startDate: '',
    link: '',
    file: undefined
  });

  const navigate = useNavigate();

  const BannerDuration = [
    {
      label: '1',
      value: '1'
    },
    {
      label: '5',
      value: '5'
    },
    {
      label: '10',
      value: '10'
    },
    {
      label: '20',
      value: '20'
    },
    {
      label: '30',
      value: '30'
    },
    {
      label: '50',
      value: '50'
    }
  ];

  let { id } = useParams();

  useEffect(() => {
    if (id !== undefined) {
      getBanner(id)
        .then((response) => {
          const data = response.data.result;
          setUploadImage(storageUrl + response?.data?.result?.file?.id);
          setData({
            name: data.name,
            expiry: data.expiry,
            startDate: data.startDate,
            link: data.link,
            file: id !== undefined ? data.file : response?.data?.file
          });
          setRangeDate([
            dayjs(data.startDate),
            dayjs(moment(data.startDate).add(+data.expiry, 'days').format('YYYY-MM-DDThh:mm:ssZ'))
          ]);
        })
        .catch((err) => console.log(err));
    } else {
      setData({
        name: '',
        expiry: '',
        startDate: '',
        link: '',
        file: undefined
      });
    }
  }, [id]);

  console.log('DATA', data);

  const onTemplateSelect = (e: any) => {
    let _totalSize = totalSize;
    let selectedFile = e.files[0];

    uploadFile(selectedFile)
      .then((response) => {
        setImage(response.data);
        setData({ ...data, file: response.data });
        setUploadImage(storageUrl + response.data.id);
      })
      .catch((err) => console.log(err));

    Object.values(e.files).map((value: any, index) => {
      return (_totalSize = value.size);
    });

    setTotalSize(_totalSize);
  };

  const onTemplateUpload = (e: any) => {
    let _totalSize = 0;
    let selectedFile = e.files[0];

    uploadFile(selectedFile)
      .then((response) => {
        setImage(response.data);
        setData({ ...data, file: response.data });
        setUploadImage(storageUrl + response.data.id);
      })
      .catch((err) => console.log(err));

    e.files.forEach((file: any) => {
      _totalSize = file.size || 0;
    });

    setTotalSize(_totalSize);
    toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
  };

  const onTemplateRemove = (file: any, callback: any) => {
    deleteFile(image.id);
    setTotalSize(totalSize - file.size);
    setImage(undefined);
    setUploadImage(undefined);
    callback();
  };

  const onTemplateClear = () => {
    deleteFile(image.id);
    setImage(undefined);
    setUploadImage(undefined);
    setTotalSize(0);
  };

  const chooseOptions = {
    icon: 'pi pi-fw pi-images',
    iconOnly: true,
    className: 'custom-choose-btn p-button-rounded p-button-outlined'
  };

  const cancelOptions = {
    icon: 'pi pi-fw pi-times',
    iconOnly: true,
    className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'
  };

  const headerTemplate = (options: any) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 1000;
    const formatedValue =
      fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

    return (
      <div
        className={className}
        style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
        {chooseButton}
        {cancelButton}
        <label>{parseFloat(`${formatedValue}`).toFixed(2)}</label>
        <ProgressBar
          value={uploadImage ? 100 : 0}
          style={{ width: '400px', height: '20px', marginLeft: 'auto' }}></ProgressBar>
      </div>
    );
  };

  const itemTemplate = (file: any, props: any) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={
            // eslint-disable-next-line react/prop-types
            props.formatSize
          }
          severity="warning"
          className="px-3 py-2 tag"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          // eslint-disable-next-line react/prop-types
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: '5em',
            borderRadius: '50%',
            height: '5.5rem',
            backgroundColor: 'var(--surface-b)',
            color: 'var(--surface-d)'
          }}></i>
        <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
          Drag and Drop Image Here
        </span>
      </div>
    );
  };

  const handleData = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleCreation = () => {
    if (data.name !== '' && data.expiry !== '' && data.link !== '' && data.file !== undefined) {
      if (id === undefined) {
        createBanner({ ...data, file: image })
          .then((response) =>
            navigate('/banner-list', {
              replace: true,
              state: { toast: toast, message: 'Uspešno kreiran novi baner' }
            })
          )
          .catch((err) => console.log(err));
      } else {
        updateBanner(id, { ...data, file: image })
          .then((response) =>
            navigate('/banner-list', {
              replace: true,
              state: { toast: toast, message: 'Uspešno ažuriran baner' }
            })
          )
          .catch((err) => console.log(err));
      }
    }
  };

  const handleRangeDatePicker = (date: any) => {
    const startDate = date[0].$d;
    const endDate = date[1].$d;

    setRangeDate([dayjs(startDate), dayjs(endDate)]);
    const timeDifference = endDate.getTime() - startDate.getTime();

    const dayDifference = timeDifference / (1000 * 3600 * 24);
    setData({
      ...data,
      startDate: startDate,
      expiry: String(dayDifference)
    });
  };

  return (
    <PageLayout>
      <div className="new-banner">
        <Breadcrumb
          title={id === undefined ? 'Baneri / Novi baner' : 'Baneri / Lista banera / Uredi baner'}
        />
        <div className="new-banners">
          <div className="input-fields">
            <InputField
              inputContainer={'input-container'}
              placeholder={'Unesite naziv banera'}
              value={data.name}
              name={'name'}
              onChange={handleData}
              inputStyle={'inputStyle'}
              label={'Naziv banera'}
            />
            <div className="input-container">
              <label className="label">Trajanje banera</label>
              <RangePicker
                className="range-date"
                showTime={false}
                format="YYYY-MM-DD"
                onChange={handleRangeDatePicker}
                value={rangeDate}
              />
            </div>
            {/* // {uploadImage !== undefined && <img src={uploadImage} alt="" />} */}
            <InputField
              inputContainer={'input-container'}
              inputStyle={'inputStyle'}
              value={data.link}
              name={'link'}
              onChange={handleData}
              placeholder={'Unesite link banera'}
              label={'Link banera'}
            />
            <div className="upload-image-container">
              <label className="label">Slika banera</label>
              {/* <form onSubmit={handleSubmit} encType="multipart/form-data">
                <input type="file" onChange={handleFileInputChange} name="photo" />
                <button type="submit">Upload</button>
              </form> */}
              <FileUpload
                ref={fileUploadRef}
                className="upload-image"
                name="demo[]"
                url="https://primefaces.org/primereact/showcase/upload.php"
                multiple={false}
                accept=" .jpg, .png, .jpeg"
                maxFileSize={1000000}
                onUpload={onTemplateUpload}
                onSelect={onTemplateSelect}
                onError={onTemplateClear}
                onClear={onTemplateClear}
                headerTemplate={headerTemplate}
                itemTemplate={itemTemplate}
                emptyTemplate={emptyTemplate}
                chooseOptions={chooseOptions}
                cancelOptions={cancelOptions}
              />
            </div>
            <div className="inner-button-container">
              <button
                disabled={data.name === '' || data.expiry === '' || data.link === '' ? true : false}
                onClick={handleCreation}
                className={
                  data.name === '' ||
                  data.expiry === '' ||
                  data.link === '' ||
                  data.file === undefined
                    ? 'btn-disabled'
                    : 'btn'
                }>
                {id === undefined ? 'Sačuvaj' : 'Izmeni'}
              </button>
            </div>
          </div>
          <div className="image-container">
            <div className="inner">
              <img className="image" src={phone} />
              {data.file !== undefined && <img className="banner" src={uploadImage} />}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default NewBanner;
