import { axiosInstance } from './AxiosDefault';

export const uploadFile = (file: any): Promise<any> => {
  const formData = new FormData();
  formData.append('photo', file);
  return axiosInstance.post('/files/upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const getOneFile = (id: string): Promise<any> => {
  return axiosInstance.get(`${`/files/storage`}/${id}`);
};

export const deleteFile = (id: string): Promise<any> => {
  return axiosInstance.delete(`${'/files'}/${id}`);
};
