import { axiosInstance } from './AxiosDefault';
import { Banner } from '../models/Banner';

export const getAllBanners = (): Promise<any> => {
  return axiosInstance.get('/banners', {
    withCredentials: false,
    headers: { 'Content-Type': 'application/json' }
  });
};

export const getBanner = (id: string): Promise<any> => {
  return axiosInstance.get(`${`/banners`}/${id}`, {
    withCredentials: false,
    headers: { 'Content-Type': 'application/json' }
  });
};

export const createBanner = (banner: Banner): Promise<any> => {
  return axiosInstance.post('/banners', banner, {
    headers: { 'Content-Type': 'application/json' }
  });
};

export const updateBanner = async (id: string, banner: Banner): Promise<any> => {
  return axiosInstance.put(`${'/banners'}/${id}`, banner, {
    headers: { 'Content-Type': 'application/json' }
  });
};

export const deleteBanner = (id: string): Promise<any> => {
  return axiosInstance.delete(`${'/banners'}/${id}`);
};

export const activeBanner = (id: string): Promise<any> => {
  return axiosInstance.put(`${'/banners/active-banner'}/${id}`);
};
