import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface IActiveClass {
  activeRow: {
    row: number;
    subMenuRow: number;
  };
}

const initialState: IActiveClass = {
  activeRow: {
    row: 0,
    subMenuRow: 0
  }
};

export const ActiveClass = createSlice({
  name: 'activeRow',
  initialState,
  reducers: {
    setActiveRow: (state, action: PayloadAction<any>) => {
      state.activeRow = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setActiveRow } = ActiveClass.actions;

export default ActiveClass.reducer;
