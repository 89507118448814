import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Header } from '../Header/Header';
import { MainMenu } from '../MainMenu/MainMenu';
import { activeUser, removeActiveUser } from '../../services/AuthService';
import './PageLayout.scss';
import Notification from '../../assets/notification.png';
import Flag from '../../assets/flag.png';
import Plus from '../../assets/plus.png';
import List from '../../assets/list.png';
import Settings from '../../assets/settings.png';
import UserYeelow from '../../assets/user-yellow.png';
import { ErrorFallback } from '../../components/ErrorFallback/ErrorFallback';

export interface PageLayoutProps {
  children?: React.ReactElement | React.ReactElement[];
}

export const PageLayout = (p: PageLayoutProps) => {
  const navigate = useNavigate();

  const sideMenuList = [
    {
      id: 1,
      label: 'Obavestenja',
      icon: Notification,
      open: false,
      items: [
        {
          mainID: 1,
          id: 1,
          label: 'Lista obavestenja',
          icon: List,
          command: () => navigate('/notification-list')
        },
        {
          mainID: 1,
          id: 2,
          label: 'Novo obavestenje',
          icon: Plus,
          command: () => navigate('/new-notification')
        }
      ]
    },
    {
      id: 2,
      label: 'Baneri',
      icon: Flag,
      open: false,
      items: [
        {
          mainID: 2,
          id: 1,
          label: 'Lista banera',
          icon: List,
          command: () => navigate('/banner-list')
        },
        {
          mainID: 2,
          id: 2,
          label: 'Novi baner',
          icon: Plus,
          command: () => navigate('/new-banner')
        }
      ]
    }
    // {
    //   id: 3,
    //   label: 'Korisnici',
    //   icon: UserYeelow,
    //   open: false,
    //   command: () => navigate('')
    // },
    // {
    //   id: 4,
    //   label: 'Podešavanje',
    //   icon: Settings,
    //   open: false,
    //   command: () => navigate('/partners')
    // }
  ];

  const handleLogout = () => {
    removeActiveUser();
    localStorage.removeItem('amss-admin-user');
    localStorage.removeItem('token');
    navigate(`/login`, { replace: true });
  };

  return (
    <div className="amss-page">
      <Header
        name={`${activeUser?.firstName} ${activeUser?.lastName}`}
        role={activeUser?.role}
        onLogoutClick={handleLogout}
      />
      <div className="amss-page-content">
        <div className="amss-main-menu-wrapper">
          <MainMenu menuItemList={sideMenuList} />
        </div>
        <div className="amss-content-wrapper">
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              // reset the state of your app so the error doesn't happen again
            }}>
            {p.children}
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};
