import './Breadcrumb.scss';

const Breadcrumb = (props: any) => {
  return (
    <div className="breadcrumb">
      <p className="header">{props.title}</p>
    </div>
  );
};

export default Breadcrumb;
