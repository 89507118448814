import React, { useEffect, useState } from 'react';
import './Sidebar.scss';
import arrowDown from '../../assets/arrow-down.png';
import arrowDownWhite from '../../assets/arrow-down-white.png';
import { useDispatch } from 'react-redux';
import { setActiveRow } from '../../store/ActiveClass/ActiveClass';
import { useSelector } from 'react-redux';
import type { RootState } from '../../store/store';
import { MenuItemProps, SidebarList, SubmenuItem } from '../../models/Sidebar';

const Sidebar = (sidebarList: SidebarList) => {
  const ActiveUser = useSelector((state: RootState) => state);

  const [dataList, setDataList] = useState<MenuItemProps[]>(sidebarList.sidebarList);
  const [activeRowId, setActiveRowId] = useState<number>(0);

  const handleOpen = (id: number) => {
    const copyArr = [...dataList];
    const filterA: any = copyArr?.find((row: MenuItemProps) => row.id === +id);

    filterA.open = !filterA.open;
    setDataList(copyArr);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    setActiveRowId(ActiveUser.ActiveClass.activeRow.row);
  }, [ActiveUser]);

  return (
    <div className="sidebar">
      {dataList.map((el: MenuItemProps, i: number) => {
        return (
          <div key={i + 1} className="item">
            <div
              onClick={() => {
                handleOpen(el.id);
                setActiveRowId(el.id);
              }}
              className={`main-part ${activeRowId === el.id && 'active'}`}>
              <img className="img-left" src={el.icon} />
              <p className="header">{el.label}</p>
              <img className="img-arrow" src={activeRowId === el.id ? arrowDownWhite : arrowDown} />
            </div>
            {el.open && el.items !== undefined ? (
              <div onClick={() => ''} className="sub-menu">
                {el.items.map((subMenuEl: SubmenuItem, i: number) => {
                  return (
                    <div
                      onClick={() => {
                        if (subMenuEl.command !== undefined) {
                          dispatch(
                            setActiveRow({ row: subMenuEl.mainID, subMenuRow: subMenuEl.id })
                          );
                          subMenuEl.command();
                        }
                      }}
                      key={i + 1}
                      className={`row ${
                        el.id === ActiveUser.ActiveClass.activeRow.row &&
                        subMenuEl.id === ActiveUser.ActiveClass.activeRow.subMenuRow
                          ? 'active-sub-menu-class'
                          : null
                      }`}>
                      <div className="inner-container">
                        <img className="img-sub-menu" src={subMenuEl.icon} />
                        <p className="sub-menu-header">{subMenuEl.label}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default Sidebar;
