import React, { useState, useRef, useEffect } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { PageLayout } from '../../layout/PageLayout/PageLayout';
import './NewNotification.scss';
import InputField from '../../components/InputField/InputField';
import { Button } from 'primereact/button';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  createNotification,
  getNotification,
  updateNotification
} from '../../services/NotificationService';
import TextareaField from '../../components/TextareaField/TextareaField';
import { Dropdown } from 'primereact/dropdown';

const NewNotification = () => {
  const [totalSize, setTotalSize] = useState(0);
  const [image, setImage] = useState<any>(undefined);
  const [uploadImage, setUploadImage] = useState<any>();
  let serverUrl = process.env.REACT_APP_API_URL;
  let storageUrl = serverUrl + 'files/storage/';
  const toast = useRef<any>(null);
  const fileUploadRef = useRef<any>(null);
  const [data, setData] = useState<any>({
    name: '',
    description: '',
    data: '',
    publishOption: undefined
  });
  const [productionInput, setProductionInput] = useState<string>('');
  const [productionIndicator, setProductionIndicator] = useState<boolean>(false);

  const [selectOptionPublish, setSelectOptionPublish] = useState<{ name: string }>();
  const options = [{ name: 'Test' }, { name: 'Production' }];

  const navigate = useNavigate();

  let { id } = useParams();

  useEffect(() => {
    if (id !== undefined) {
      getNotification(id)
        .then((response) => {
          const data = response.data.result;
          setUploadImage(storageUrl + response?.data?.result?.file?.id);
          setData({
            name: data.name,
            description: data.description,
            data: data.data,
            publishOption: { name: data.publishOption }
          });
        })
        .catch((err) => console.log(err));
    } else {
      setData({
        name: '',
        description: '',
        data: '',
        publishOption: undefined
      });
    }
  }, [id]);

  const handleData = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleProductionInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProductionInput(e.target.value);
    if (e.target.value == 'Production') {
      setProductionIndicator(true);
    } else {
      setProductionIndicator(false);
    }
  };

  const selectDropFunc = (value: { name: string }) => {
    setSelectOptionPublish(value);
    setData({
      ...data,
      publishOption: value.name
    });
  };

  const handleCreation = () => {
    if (data.name !== '' && data.description !== '' && data.publishOption) {
      if (id === undefined) {
        createNotification(data)
          .then((response) =>
            navigate('/notification-list', {
              replace: true,
              state: { toast: toast, message: 'Uspešno kreirano novo obavestenje' }
            })
          )
          .catch((err) => console.log(err));
      } else {
        updateNotification(id, { ...data, name: data.name, description: data.description })
          .then((response) =>
            navigate('/notification-list', {
              replace: true,
              state: { toast: toast, message: 'Uspešno ažurirano obavestenje' }
            })
          )
          .catch((err) => console.log(err));
      }
    }
  };

  return (
    <PageLayout>
      <div className="new-banner">
        <Breadcrumb
          title={
            id === undefined
              ? 'Obavestenja / Nova obavestenja'
              : 'Obavestenja / Lista obavestenja / Uredi obavestenja'
          }
        />
        <div className="new-banners">
          <div className="input-fields-a">
            <InputField
              inputContainer={'input-container'}
              placeholder={'Unesite naziv obavestenja'}
              value={data.name}
              name={'name'}
              onChange={handleData}
              inputStyle={'inputStyle'}
              label={'Naziv obavestenja'}
            />

            <TextareaField
              inputContainer={'textarea-container'}
              inputStyle={'inputStyle'}
              value={data.description}
              name={'description'}
              onChange={handleData}
              placeholder={'Unesite kratak opis'}
              label={'Kratak opis'}
            />

            <TextareaField
              inputContainer={'textarea-container'}
              inputStyle={'inputStyle'}
              value={data.data}
              name={'data'}
              onChange={handleData}
              placeholder={'Unesite opis'}
              label={'Opis'}
              minHeight={'150px'}
            />

            <span className="drop-d">
              <label>Publish:</label>
              <Dropdown
                value={selectOptionPublish}
                onChange={(e) => selectDropFunc(e.value)}
                options={options}
                optionLabel="name"
                placeholder="Select"
              />
            </span>
            {selectOptionPublish?.name === 'Production' && (
              <div className="production-input-wrapper">
                <InputField
                  inputContainer="input-container"
                  placeholder="Unesite Production"
                  value={productionInput}
                  name="productionInput"
                  onChange={handleProductionInputChange}
                  inputStyle="inputStyle"
                  label="Production"
                />
                <span
                  className="info-icon"
                  title="Morate upisati Production ako želite da se notifikacija pošalje svim korisnicima.">
                  ?
                </span>
              </div>
            )}
            <div className="inner-button-container">
              {id === undefined ? (
                <button
                  disabled={
                    data.name === '' ||
                    data.description === '' ||
                    data.publishOption == undefined ||
                    (data.publishOption === 'Production' && !productionIndicator)
                      ? true
                      : false
                  }
                  onClick={handleCreation}
                  className={
                    data.name === '' ||
                    data.description === '' ||
                    data.publishOption == undefined ||
                    (data.publishOption === 'Production' && !productionIndicator)
                      ? 'btn-disabled'
                      : 'btn'
                  }>
                  Sacuvaj
                </button>
              ) : (
                <button
                  disabled={data.name === '' || data.description === '' ? true : false}
                  onClick={handleCreation}
                  className={data.name === '' || data.description === '' ? 'btn-disabled' : 'btn'}>
                  Izmeni
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default NewNotification;
