import { deleteBanner } from '../../services/BannerService';
import { deleteNotification } from '../../services/NotificationService';
import './DeleteModal.scss';

const DeleteModal = (props: any) => {
  const handleMultipleDelete = () => {
    const length = props.selectedRow.length;
    props.selectedRow.map((el: any, index: number) => {
      deleteBanner(el.id)
        .then((response) => {
          if (index + 1 === length && response.status === 200) {
            props.handleMultipleDeleteModal();
            props.handleDeletedSelectedRows();
            props.handleShowDeleteMultipleToast();
          }
        })
        .catch((err) => console.log(err));
    });
  };

  const handleMultipleDeleteNotification = () => {
    const length = props.selectedRow.length;
    props.selectedRow.map((el: any, index: number) => {
      deleteNotification(el.id).then((response) => {
        if (index + 1 === length && response.status === 200) {
          props.handleMultipleDeleteModal();
          props.handleDeletedSelectedRows();
          props.handleShowDeleteMultipleToast();
        }
      });
    });
  };

  return (
    <div className="delete-modal">
      <div className="header-container">
        <p className="header">
          {props.notification ? 'Potvrda brisanja notifikacija' : 'Potvrda brisanja banera'}
        </p>
        <button onClick={props.handleMultipleDeleteModal} className="delete-btn">
          <i className="pi pi-times" style={{ color: 'white' }}></i>
        </button>
      </div>
      <p className="text">{`Da li ste sigurni da želite da izbrisete obeležene  ${
        props.notification ? 'notifikacije' : 'banere'
      }?`}</p>
      <div className="btn-container">
        <button
          onClick={props.notification ? handleMultipleDeleteNotification : handleMultipleDelete}
          className="btn yellow">
          <p className="blue-text">Da</p>
        </button>
        <button onClick={props.handleMultipleDeleteModal} className="btn blue">
          Ne
        </button>
      </div>
    </div>
  );
};

export default DeleteModal;
