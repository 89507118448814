import { createBanner } from '../../services/BannerService';
import './EditModal.scss';

const EditModal = (props: any) => {
  const handleDeleteBanner = () => {
    if (props.copyObject === undefined) return;
    console.log('PROPS COPY', props.copyObject);
    createBanner({ ...props.copyObject, startDate: new Date().toISOString() })
      .then((response) => {
        props.handleShowEditModal();
        props.handleShowCopyToast();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="edit-modal">
      <div className="header-container">
        <p className="header">Potvrda kopiranja banera</p>
        <button onClick={props.handleShowEditModal} className="delete-btn">
          <i className="pi pi-times" style={{ color: 'white' }}></i>
        </button>
      </div>
      <p className="text">Da li ste sigurni da želite da kopirate baner?</p>
      <div className="btn-container">
        <button onClick={handleDeleteBanner} className="btn yellow">
          <p className="blue-text">Da</p>
        </button>
        <button onClick={props.handleShowEditModal} className="btn blue">
          Ne
        </button>
      </div>
    </div>
  );
};

export default EditModal;
