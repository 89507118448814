import { useEffect } from 'react';

const FuelCost = () => {
  useEffect(() => {
    window.location.replace('https://must.gov.rs/sekcija/sr/23/vesti.php');
  }, []);

  return <div></div>;
};

export default FuelCost;
