import './UserHeader.scss';
import userImage from '../../assets/user.png';

interface UserHeaderProps {
  name: string;
  role?: any;
}

export const UserHeader = (p: UserHeaderProps) => {
  const { name, role } = p;

  return (
    <div className="user-header-container">
      <div className="user-header-profile-image">
        <div className="user-header-image">
          <img id="userHeaderImage" src={userImage} alt="Profilna slika" />
        </div>
      </div>
      <div className="user-header-data">
        <div className="user-header-name">{name}</div>
        <div className="user-header-role">{role?.name}</div>
      </div>
    </div>
  );
};
