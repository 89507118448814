import { axiosInstance } from './AxiosDefault';
import { User, UserDTO } from '../models/User';
import { RoleType } from '../models/Role';
import { useSelector } from 'react-redux';

export let activeUser: UserDTO | undefined;

try {
  activeUser = JSON.parse(<string>localStorage.getItem('amss-admin-user'));
} catch (error) {
  activeUser = undefined;
}

//TODO: remove next two lines, only for testing purposes

//activeUser = {id: 'company', firstName: 'Zoran', lastName: 'Tasic', role: {id: 'company'}};

export const getAccessToken = (username: string, password: any): Promise<any> => {
  return axiosInstance.post(
    `/auth/token`,
    {
      username: username,
      password: password
    },
    { withCredentials: false, headers: { 'Content-Type': 'application/json' } }
  );
};

export const setUser = (data: User) => {
  activeUser = data.user;
  localStorage.setItem('amss-admin-user', JSON.stringify(data.user));
  localStorage.setItem('token', data.token);
};

export const removeActiveUser = () => {
  activeUser = undefined;
  localStorage.removeItem('amss-admin-user');
  localStorage.removeItem('token');
};
