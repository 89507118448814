import { useState, useEffect, useRef } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { PageLayout } from '../../layout/PageLayout/PageLayout';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './NotificationList.scss';
import deleteIcon from '../../assets/delete.png';
import copyIcon from '../../assets/copy.png';
import editPencil from '../../assets/edit-pencil.png';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Modal from '../../components/Modal/Modal';
import { useNavigate, useLocation } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import EditModal from '../../components/EditModal/EditModal';
import { useDispatch } from 'react-redux';
import { setActiveRow } from '../../store/ActiveClass/ActiveClass';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import Overlay from '../../components/Overlay/Overlay';
import { getAllNotification } from '../../services/NotificationService';

const NotificationList = () => {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [selectedRow, setSelectedRow] = useState<any>(undefined);
  const [listOfNotifications, setlistOfNotifications] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const toast = useRef<any>(null);
  const [copyObject, setCopyObject] = useState<any>(undefined);
  const dataTableRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getAllNotification()
      .then((response) => {
        state?.toast?.current.show({
          severity: 'success',
          detail: state.message,
          life: 3000
        });
        navigate(location.pathname, { replace: true });
        setlistOfNotifications(response.data.result);
      })
      .catch((err) => console.log(err));
  }, [showModal, showEditModal, showDeleteModal]);

  // const exportExcel = () => {
  //   const filterObject = listOfNotifications.map((el: any) => {
  //     return {
  //       Naziv: el.name,
  //       Trajanje: el.expiry,
  //       Početak: moment(el.createdAt).format('DD.MM.YYYY.'),
  //       Završetak: moment(el.createdAt).add(+el.expiry, 'days').format('DD.MM.YYYY.')
  //     };
  //   });

  //   import('xlsx').then((xlsx) => {
  //     const worksheet = xlsx.utils.json_to_sheet(filterObject);
  //     const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
  //     const excelBuffer = xlsx.write(workbook, {
  //       bookType: 'xlsx',
  //       type: 'array'
  //     });

  //     saveAsExcelFile(excelBuffer, 'Lista banera');
  //   });
  // };

  // const saveAsExcelFile = (buffer: any, fileName: any) => {
  //   import('file-saver').then((module: any) => {
  //     if (module && module.default) {
  //       let EXCEL_TYPE =
  //         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  //       let EXCEL_EXTENSION = '.xlsx';
  //       const data = new Blob([buffer], {
  //         type: EXCEL_TYPE
  //       });
  //       module.default.saveAs(data, fileName + '_export' + EXCEL_EXTENSION);
  //     }
  //   });
  // };

  const handleOptions = (rowData: any) => {
    return (
      <div className="options">
        <div className="inner-container">
          <Link to={`/edit-notification/${rowData.id}`} className="link">
            <img src={editPencil} />
          </Link>
          {selectedRow === undefined || selectedRow?.length === 0 ? (
            <button
              onClick={() => {
                setShowModal(true);
                setDeleteId(rowData.id);
              }}
              className="button">
              <img src={deleteIcon} />
            </button>
          ) : null}
        </div>
      </div>
    );
  };

  const handleDescription = (rowData: any) => {
    return rowData.description.length > 40
      ? `${rowData.description.slice(0, 40)}...`
      : rowData.description;
  };

  const dateFormat = (rowData: any) => {
    return moment(rowData.createdAt).format('DD.MM.YYYY. HH:mm');
  };

  // const endDateFormat = (rowData: any) => {
  //   return moment(rowData.createdAt).add(+rowData.expiry, 'days').format('DD.MM.YYYY.');
  // };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  // const handleShowEditModal = () => {
  //   setCopyObject(undefined);
  //   setShowEditModal(!showEditModal);
  // };

  const handleShowDeleteToast = () => {
    return toast.current.show({
      severity: 'success',
      detail: `Uspešno ste izbrisali notifikaciju`,
      life: 3000
    });
  };

  // const handleShowCopyToast = () => {
  //   return toast.current.show({
  //     severity: 'success',
  //     detail: `Uspešno ste kopirali obavestenje`,
  //     life: 3000
  //   });
  // };

  const handleShowDeleteMultipleToast = () => {
    return toast.current.show({
      severity: 'success',
      detail: `Uspešno ste izbrisali obeležene obavjestis`,
      life: 3000
    });
  };

  const handleMultipleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeletedSelectedRows = () => {
    setSelectedRow(undefined);
  };

  return (
    <PageLayout>
      <div className="notification-list">
        <Toast ref={state?.toast} position={'top-right'} />
        <Toast ref={toast} position={'top-right'} />
        <Breadcrumb title={'Obavestenja / Lista obavestenja'} />
        <div className="table-container">
          {selectedRow?.length >= 1 || showDeleteModal ? (
            <button onClick={handleMultipleDeleteModal} className="button-delete-selected">
              <img src={deleteIcon} />
            </button>
          ) : null}
          <DataTable
            ref={dataTableRef}
            selection={selectedRow}
            selectionMode="checkbox"
            onSelectionChange={(e: any) => setSelectedRow(e.value)}
            value={listOfNotifications}
            className="p-datatable-lg"
            paginator={true}
            paginatorPosition="bottom"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate={`Prikazuje {first} do {last} od {totalRecords}`}
            rows={20}
            emptyMessage={'Lista banera je prazna.'}
            rowsPerPageOptions={[10, 20, 50]}
            sortField="createdAt"
            sortOrder={-1}
            scrollHeight="60vh">
            <Column selectionMode="multiple" className="column-container-checkbox" />
            <Column
              sortable
              className="column-container"
              field="name"
              header={'Naziv obavestenja'}
            />
            <Column
              sortable
              className="column-container"
              field="createdAt"
              header={'Datum kreiranja'}
              body={dateFormat}
            />

            <Column
              className="column-container-description"
              body={handleDescription}
              header={'Kratak opis'}
            />
            <Column className="column-container-options" body={handleOptions} header={'Opcije'} />
          </DataTable>
        </div>
        <div className="bottom-buttons">
          <div className="inner-button-container">
            <button
              onClick={() => {
                navigate('/new-notification');
                dispatch(setActiveRow({ row: 1, subMenuRow: 2 }));
              }}
              className="btn">
              Novo obavestenje
            </button>
            {/* <button onClick={exportExcel} className="btn">
              Export
            </button> */}
          </div>
        </div>
        {showModal && (
          <>
            <Modal
              notification={true}
              handleShowDeleteToast={handleShowDeleteToast}
              deleteId={deleteId}
              handleShowModal={handleShowModal}
            />
            <Overlay />
          </>
        )}
        {showDeleteModal && (
          <>
            <DeleteModal
              notification={true}
              selectedRow={selectedRow}
              handleMultipleDeleteModal={handleMultipleDeleteModal}
              handleDeletedSelectedRows={handleDeletedSelectedRows}
              handleShowDeleteMultipleToast={handleShowDeleteMultipleToast}
            />
            <Overlay />
          </>
        )}
        {/* {showEditModal && (
          <>
            <EditModal
              copyObject={copyObject}
              handleShowCopyToast={handleShowCopyToast}
              handleShowEditModal={handleShowEditModal}
            />
            <Overlay />
          </>
        )} */}
      </div>
    </PageLayout>
  );
};

export default NotificationList;
