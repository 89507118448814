import { UserHeader } from '../UserHeader/UserHeader';
import './Header.scss';
import dashboardLogo from '../../assets/dashboard-logo.png';
import { Link } from 'react-router-dom';
import logOut from '../../assets/log-out.png';
import { Role } from '../../models/Role';

interface HeaderProps {
  name: string;
  role?: Role;
  onLogoutClick: () => void;
}

export const Header = (p: HeaderProps) => {
  const { name, role, onLogoutClick } = p;

  return (
    <div className="header-container">
      <Link to="/dashboard" className="header-logo">
        <img src={dashboardLogo} alt="logo" />
      </Link>
      <div className="header-user">
        <UserHeader name={name} role={role} />
      </div>
      <div className="header-buttons">
        <img className="image" onClick={onLogoutClick} src={logOut} />
        <div onClick={onLogoutClick} className="header-button-label">
          {'Logout'}
        </div>
      </div>
    </div>
  );
};
