import { useState, useEffect, useRef } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { PageLayout } from '../../layout/PageLayout/PageLayout';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './BannerList.scss';
import deleteIcon from '../../assets/delete.png';
import copyIcon from '../../assets/copy.png';
import activeIcon from '../../assets/active.png';
import editPencil from '../../assets/edit-pencil.png';
import { Link } from 'react-router-dom';
import { getAllBanners } from '../../services/BannerService';
import moment from 'moment';
import Modal from '../../components/Modal/Modal';
import { useNavigate, useLocation } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import EditModal from '../../components/EditModal/EditModal';
import { useDispatch } from 'react-redux';
import { setActiveRow } from '../../store/ActiveClass/ActiveClass';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import Overlay from '../../components/Overlay/Overlay';
import { Checkbox } from 'primereact/checkbox';
import ModalActiveBanner from '../../components/ModalActiveBanner/ModalActiveBanner';

const BannerList = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalActiveBanner, setShowModalActiveBanner] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [activeId, setActiveId] = useState('');
  const [selectedRow, setSelectedRow] = useState<any>(undefined);
  const [listOfBunners, setListOfBunners] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const toast = useRef<any>(null);
  const [copyObject, setCopyObject] = useState<any>(undefined);
  const dataTableRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getAllBanners()
      .then((response) => {
        state?.toast?.current.show({
          severity: 'success',
          detail: state.message,
          life: 3000
        });
        navigate(location.pathname, { replace: true });
        setListOfBunners(response.data.result);
      })
      .catch((err) => console.log(err));
  }, [showModal, showEditModal, showDeleteModal, showModalActiveBanner]);

  const exportExcel = () => {
    const filterObject = listOfBunners.map((el: any) => {
      return {
        Naziv: el.name,
        Trajanje: el.expiry,
        Početak: moment(el.createdAt).format('DD.MM.YYYY.'),
        Završetak: moment(el.createdAt).add(+el.expiry, 'days').format('DD.MM.YYYY.')
      };
    });

    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(filterObject);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });

      saveAsExcelFile(excelBuffer, 'Lista banera');
    });
  };

  const saveAsExcelFile = (buffer: any, fileName: any) => {
    import('file-saver').then((module: any) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });
        module.default.saveAs(data, fileName + '_export' + EXCEL_EXTENSION);
      }
    });
  };

  const handleOptions = (rowData: any) => {
    return (
      <div className="options">
        <div className="inner-container">
          <Link to={`/edit-banner/${rowData.id}`} className="link">
            <img src={editPencil} />
          </Link>
          <button
            onClick={() => {
              setShowEditModal(true);
              setCopyObject({
                name: `${rowData.name} COPY`,
                expiry: rowData.expiry,
                link: rowData.link,
                file: rowData.file
              });
            }}
            className="button">
            <img src={copyIcon} />
          </button>
          {selectedRow === undefined || selectedRow?.length === 0 ? (
            <button
              onClick={() => {
                setShowModal(true);
                setDeleteId(rowData.id);
              }}
              className="button">
              <img src={deleteIcon} />
            </button>
          ) : null}
          <button
            onClick={() => {
              setShowModalActiveBanner(true);
              setActiveId(rowData.id);
            }}
            className="button">
            <img src={activeIcon} />
          </button>
        </div>
      </div>
    );
  };

  const handleActiveBanner = (rowData: any) => {
    return <Checkbox checked={rowData.activeBanner} />;
  };

  const startDateFormat = (rowData: any) => {
    const date = new Date(rowData.startDate);
    return moment(rowData.startDate).format('DD.MM.YYYY.');
  };

  const endDateFormat = (rowData: any) => {
    return moment(rowData.startDate).add(+rowData.expiry, 'days').format('DD.MM.YYYY.');
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleShowModalActiveBanner = () => {
    setShowModalActiveBanner(!showModalActiveBanner);
  };

  const handleShowEditModal = () => {
    setCopyObject(undefined);
    setShowEditModal(!showEditModal);
  };

  const handleShowDeleteToast = () => {
    return toast.current.show({
      severity: 'success',
      detail: `Uspešno ste izbrisali baner`,
      life: 3000
    });
  };

  const handleShowActiveBannerToast = () => {
    return toast.current.show({
      severity: 'success',
      detail: `Uspešno ste postavili aktivan baner`,
      life: 3000
    });
  };

  const handleShowCopyToast = () => {
    return toast.current.show({
      severity: 'success',
      detail: `Uspešno ste kopirali baner`,
      life: 3000
    });
  };

  const handleShowDeleteMultipleToast = () => {
    return toast.current.show({
      severity: 'success',
      detail: `Uspešno ste izbrisali obeležene banere`,
      life: 3000
    });
  };

  const handleMultipleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeletedSelectedRows = () => {
    setSelectedRow(undefined);
  };

  return (
    <PageLayout>
      <div className="banner-list">
        <Toast ref={state?.toast} position={'top-right'} />
        <Toast ref={toast} position={'top-right'} />
        <Breadcrumb title={'Baneri / Lista banera'} />
        <div className="table-container">
          {selectedRow?.length >= 1 || showDeleteModal ? (
            <button onClick={handleMultipleDeleteModal} className="button-delete-selected">
              <img src={deleteIcon} />
            </button>
          ) : null}
          <DataTable
            ref={dataTableRef}
            selection={selectedRow}
            selectionMode="checkbox"
            onSelectionChange={(e: any) => setSelectedRow(e.value)}
            value={listOfBunners}
            className="p-datatable-lg"
            paginator={true}
            paginatorPosition="bottom"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate={`Prikazuje {first} do {last} od {totalRecords}`}
            rows={20}
            emptyMessage={'Lista banera je prazna.'}
            rowsPerPageOptions={[10, 20, 50]}
            sortField="startDate"
            sortOrder={-1}
            scrollHeight="60vh">
            <Column selectionMode="multiple" className="column-container-checkbox" />
            <Column sortable className="column-container" field="name" header={'Naziv banera'} />
            <Column
              body={handleActiveBanner}
              className="column-container-active-banner"
              header={'Aktivan baner'}
            />
            <Column
              sortable
              className="column-container"
              field="expiry"
              header={'Trajanje banera'}
            />
            <Column
              sortable
              className="column-container"
              body={startDateFormat}
              field="startDate"
              header={'Datum početka'}
            />
            <Column className="column-container" body={endDateFormat} header={'Datum završetka'} />
            <Column className="column-container-options" body={handleOptions} header={'Opcije'} />
          </DataTable>
        </div>
        <div className="bottom-buttons">
          <div className="inner-button-container">
            <button
              onClick={() => {
                navigate('/new-banner');
                dispatch(setActiveRow({ row: 2, subMenuRow: 2 }));
              }}
              className="btn">
              Novi baner
            </button>
            <button onClick={exportExcel} className="btn">
              Export
            </button>
          </div>
        </div>
        {showModal && (
          <>
            <Modal
              handleShowDeleteToast={handleShowDeleteToast}
              deleteId={deleteId}
              handleShowModal={handleShowModal}
            />
            <Overlay />
          </>
        )}
        {showModalActiveBanner && (
          <>
            <ModalActiveBanner
              activeId={activeId}
              handleShowModalActiveBanner={handleShowModalActiveBanner}
              handleShowActiveBannerToast={handleShowActiveBannerToast}
            />
            <Overlay />
          </>
        )}
        {showEditModal && (
          <>
            <EditModal
              copyObject={copyObject}
              handleShowCopyToast={handleShowCopyToast}
              handleShowEditModal={handleShowEditModal}
            />
            <Overlay />
          </>
        )}
        {showDeleteModal && (
          <>
            <DeleteModal
              selectedRow={selectedRow}
              handleMultipleDeleteModal={handleMultipleDeleteModal}
              handleDeletedSelectedRows={handleDeletedSelectedRows}
              handleShowDeleteMultipleToast={handleShowDeleteMultipleToast}
            />
            <Overlay />
          </>
        )}
      </div>
    </PageLayout>
  );
};

export default BannerList;
