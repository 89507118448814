import { deleteBanner } from '../../services/BannerService';
import { deleteNotification } from '../../services/NotificationService';
import './Modal.scss';

const Modal = (props: any) => {
  const handleDeleteBanner = () => {
    deleteBanner(props.deleteId)
      .then((response) => {
        props.handleShowModal();
        props.handleShowDeleteToast();
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteNotification = () => {
    deleteNotification(props.deleteId)
      .then((response) => {
        props.handleShowModal();
        props.handleShowDeleteToast();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="modal">
      <div className="header-container">
        <p className="header">
          {props.notification ? 'Potvrda brisanja notifikacije' : 'Potvrda brisanja banera'}
        </p>
        <button onClick={props.handleShowModal} className="delete-btn">
          <i className="pi pi-times" style={{ color: 'white' }}></i>
        </button>
      </div>
      <p className="text">{`Da li ste sigurni da želite da izbrisete ${
        props.notification ? 'notifikaciju' : 'baner'
      }?`}</p>
      <div className="btn-container">
        <button
          onClick={props.notification ? handleDeleteNotification : handleDeleteBanner}
          className="btn yellow">
          <p className="blue-text">Da</p>
        </button>
        <button onClick={props.handleShowModal} className="btn blue">
          Ne
        </button>
      </div>
    </div>
  );
};

export default Modal;
