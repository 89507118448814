import './Home.scss';
import type { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { PageLayout } from '../../layout/PageLayout/PageLayout';
import backgroudLogo from '../../assets/background-logo.png';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setActiveRow } from '../../store/ActiveClass/ActiveClass';

const Home = () => {
  const ActiveUser = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveRow({ row: 0, subMenuRow: 0 }));
  }, []);

  return (
    <PageLayout>
      <div className="home-page">
        <img className="img" src={backgroudLogo} />
      </div>
    </PageLayout>
  );
};

export default Home;
