import { MenuItemProps } from '../../models/Sidebar';
import './MainMenu.scss';
import Sidebar from '../../components/Sidebar/Sidebar';

interface MainMenuProps {
  menuItemList: MenuItemProps[];
}

export const MainMenu = (p: MainMenuProps) => {
  const { menuItemList } = p;

  return (
    <div className="main-menu-container">
      <Sidebar sidebarList={menuItemList} />
    </div>
  );
};
