import { axiosInstance } from './AxiosDefault';
import { Notification } from '../models/Notification';

export const getAllNotification = (): Promise<any> => {
  return axiosInstance.get('/notifications', {
    withCredentials: false,
    headers: { 'Content-Type': 'application/json' }
  });
};

export const getNotification = (id: string): Promise<any> => {
  return axiosInstance.get(`${`/notifications`}/${id}`, {
    withCredentials: false,
    headers: { 'Content-Type': 'application/json' }
  });
};

export const createNotification = (notification: Notification): Promise<any> => {
  return axiosInstance.post('/notifications', notification, {
    headers: { 'Content-Type': 'application/json' }
  });
};

export const updateNotification = async (id: string, notification: Notification): Promise<any> => {
  return axiosInstance.put(`${'/notifications'}/${id}`, notification, {
    headers: { 'Content-Type': 'application/json' }
  });
};

export const deleteNotification = (id: string): Promise<any> => {
  return axiosInstance.delete(`${'/notifications'}/${id}`);
};
