import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedElement } from '../auth/ProtectedElement';
import Login from '../pages/Login/Login';
import Home from '../pages/Home/Home';
import BannerList from '../pages/BannerList/BannerList';
import NewBanner from '../pages/NewBanner/NewBanner';
import NotificationList from '../pages/NotificationList/NotificationList';
import NewNotification from '../pages/NewNotification/NewNotification';
import FuelCost from '../pages/FuelCost/FuelCost';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedElement>
            <Login />
          </ProtectedElement>
        }
      />

      <Route
        path="dashboard"
        element={
          <ProtectedElement>
            <Home />
          </ProtectedElement>
        }
      />
      <Route
        path="banner-list"
        element={
          <ProtectedElement allowedTo={[]}>
            <BannerList />
          </ProtectedElement>
        }
      />
      <Route
        path="new-banner"
        element={
          <ProtectedElement allowedTo={[]}>
            <NewBanner />
          </ProtectedElement>
        }
      />
      <Route
        path="edit-banner/:id"
        element={
          <ProtectedElement allowedTo={[]}>
            <NewBanner />
          </ProtectedElement>
        }
      />
      <Route
        path="notification-list"
        element={
          <ProtectedElement allowedTo={[]}>
            <NotificationList />
          </ProtectedElement>
        }
      />
      <Route
        path="notification/:id"
        element={
          <ProtectedElement allowedTo={[]}>
            <NewNotification />
          </ProtectedElement>
        }
      />

      <Route
        path="new-notification"
        element={
          <ProtectedElement allowedTo={[]}>
            <NewNotification />
          </ProtectedElement>
        }
      />

      <Route
        path="edit-notification/:id"
        element={
          <ProtectedElement allowedTo={[]}>
            <NewNotification />
          </ProtectedElement>
        }
      />
      <Route path="cene-goriva" element={<FuelCost />} />
      <Route path="login" element={<Login />} />
      <Route path="*" element={<div>nothing here</div>} />
    </Routes>
  );
};
