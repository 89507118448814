import React from 'react';
import './InputField.scss';

export interface InputProp {
  label: string;
  inputContainer: string;
  inputStyle: string;
  placeholder?: string;
  value?: string;
  name: string;
  onChange: any;
}

const InputField = (props: InputProp) => {
  return (
    <div className={props.inputContainer}>
      <label className="label">{props.label}</label>
      <input
        name={props.name}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder}
        className={props.inputStyle}
      />
    </div>
  );
};

export default InputField;
